/* ./styles/globals.css */
label.pass{
  margin-top:10px;
}
.main{
  padding:10px 0;
}

.nav {
  height: 75px;
  width:auto;
  padding: 10px;
  background-color: #0E101D;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav a{
  color:#fff;
}
.nav a:hover{
  color: #276BEC;
  text-decoration:underline;
}
.nav ul {
  display: flex;
  justify-content: flex-start;

  list-style: none;
  flex-direction: column;
}
.nav ul li{
  display:flex;
  background-color:none;
  border:0;
  width:100%;
  justify-content: flex-start;
}
.nav ul li a {
  margin: 5px 15px;
  padding:10px;
}
.nav ul li:hover{
  text-decoration: underline;
}
.nav ul li a:hover{
  text-decoration: underline;
}
.container {
  min-height: 100vh;
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.main {
  padding: 5rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.footer {
  margin-top:1rem;
  width: 100%;
  padding:20px;
  background-color:#0E101D;
  border-top: 1px solid #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self:flex-end;
}
.footer p{
  text-align:center;
}


/* css slideout menu */
#menuToggle {
  opacity: 0;
  position:absolute;
}
#menuToggle:checked ~ .menuBtn > span {
  transform: rotate(45deg);
}
#menuToggle:checked ~ .menuBtn > span::before {
  top: 0;
  transform: rotate(0);
}
#menuToggle:checked ~ .menuBtn > span::after {
  top: 0;
  transform: rotate(90deg);
}
#menuToggle:checked ~ .menuBox {
  visibility: visible;
  left: 0;
}

.menuBtn {
  display: flex;
  align-items: center;
  position: fixed;
  top: .8rem;
  right: 1rem;
  width: 26px;
  height: 26px;
  cursor: pointer;
  z-index: 9999!important;
}

.menuBtn > span,
.menuBtn > span::before,
.menuBtn > span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #ccc;
  transition-duration: .25s;
}
.menuBtn > span::before {
  content: '';
  top: -8px;
}
.menuBtn > span::after {
  content: '';
  top: 8px;
}
.menuBox {
  display: block;
  position: fixed;
  visibility: hidden;
  top: 3.6rem;
  left: 0;
  text-align:left;
  width: 20%;
  height: 100%;
  margin: 0;
  padding: 40px 0;
  list-style: none;
  background-color: #0E101D;
  box-shadow: 1px 0px 6px rgba(0, 0, 0, .2);
  transition-duration: .25s;
  z-index:12;
}
.menuItem {
  display: block;
  padding: 12px 24px;
  color: #fff;
  font-family: sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  transition-duration: .25s;
}
.menuItem:hover, .menuItem:hover {
  color: #276BEC;
  text-decoration: underline;
}

